
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Instansi">
                  <div role="group" class="form-group">
                    <label class>Instansi</label>
                    <v-select
                      v-model="defaultSelected"
                      item-text="nama_instansi"
                      item-value="id_instansi"
                      disabled
                      :options="optionsInstansi"
                      label="nama_instansi"
                      :filterable="false"
                      @search="onSearch"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                  <div role="group" class="form-group">
                    <label class>OPD</label>
                    <v-select
                      v-model="form.id_opd"
                      :options="optionsOPD"
                      label="nama_opd"
                      :reduce="opd => opd.id_opd"
                      :filterable="true"
                      @search="onSearchOPD"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                  <div role="group" class="form-group">
                    <label class>Unit Kerja</label>
                    <v-select
                      v-model="form.id_unit_kerja"
                      :options="optionsUnitKerja"
                      label="nama_unit_kerja"
                      :reduce="unit_kerja => unit_kerja.id_unit_kerja"
                      :filterable="true"
                      @search="onSearchUnitKerja"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Jabatan Umum">
                  <div role="group" class="form-group">
                    <label class>Jabatan</label>
                    <v-select
                      v-model="Selectedjabatan"
                      :options="optionsJabatanUmum"
                      label="nama_jabatan"
                      :reduce="jabatan => jabatan"
                      :filterable="false"
                      @search="onSearchJabatan"
                      @input="onChangesJabatan($event)"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider
                  rules="required|numeric"
                  v-slot="{ errors }"
                  name="Kelas Jabatan"
                >
                  <CInput
                    label="Kelas Jabatan"
                    placeholder="Kelas Jabatan"
                    v-model="form.kelas_jabatan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="required|numeric" v-slot="{ errors }" name="ABK">
                  <CInput
                    label="Analisa Beban Kerja"
                    placeholder="Analisa Beban kerja"
                    v-model="form.abk"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
              <CCol col="6" class="text-right">
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VSelect from "vue-select";
import _ from "lodash";
import Swal from "sweetalert2";
export default {
  name: "FormAbkUmum",
  props: ["item"],
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
  },
  data() {
    return {
      id: null,
      errorMessage: null,
      defaultSelected: {
        nama_instansi: "Purwakarta",
        id_instansi: 3214,
      },
      form: {
        nama_instansi: "Purwakarta",
        id_instansi: 3214,
        id_unit_kerja: null,
        id_jabatan_umum: null,
        kelas_jabatan: null,
        abk: null,
        id_mst_jabatan: null,
        id_opd: null
      },
      loading: false,
      optionsInstansi: [],
      optionsUnitKerja: [],
      optionsJabatanUmum: [],
      optionsOPD: [],
      Selectedjabatan: []
    };
  },
  watch: {
    "form.id_opd": function () {
      this.searchUnitKerja("", this);
    },
    "form.id_unit_kerja": function () {
      this.onSearchJabatan("", this);
    },
    item: function (item) {
      if (item) {
        const array2 = [];
        array2.push({
          id_unit_kerja: item.id_unit_kerja,
          nama_unit_kerja: item.nama_unit_kerja
        });
        this.optionsUnitKerja = array2;
        const array3 = [];
        array3.push({
          id_jabatan: item.id_jabatan,
          nama_jabatan: item.nama_jabatan
        });
        this.optionsJabatanUmum = array3;
        this.Selectedjabatan = array3;
        const array4 = [];
        array4.push({
          id_opd: item.id_opd,
          nama_opd: item.nama_opd
        });
        this.optionsOPD = array4;

        this.form.id_unit_kerja = item.id_unit_kerja;
        this.form.id_jabatan_umum = parseInt(item.id_jabatan);
        this.form.kelas_jabatan = item.kelas_jabatan;
        this.form.abk = item.abk;
        this.form.id_opd = item.id_opd;
        this.form.id_mst_jabatan = item.id_mst_jabatan;
        this.id = item.id_abk_umum;
      }
    },
  },
  methods: {
    loadSelect() {
      this.search("", this);
      this.searchJabatan("", this);
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("jabatan_umum_abk/update", {
              data: this.form,
              id: vm.id,
            })
          : await this.$store.dispatch("jabatan_umum_abk/store", this.form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              vm.form.id_instansi = null;
              vm.form.id_unit_kerja = null;
              vm.form.id_jabatan_umum = null;
              vm.form.kelas_jabatan = null;
              vm.form.abk = null;
              vm.form.id_mst_jabatan = null;
              vm.form.id_opd = null;
              vm.id = null;
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
    async onSearch(search, loading) {
      loading(true);
      await this.search(search, this);
      loading(false);
    },
    search: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "master_instansi/autocomplete",
        search
      );
      if (search) vm.optionsInstansi = options;
    }, 300),
    async onSearchUnitKerja(search, loading) {
      loading(true);
      await this.searchUnitKerja(search, this);
      loading(false);
    },
    searchUnitKerja: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("jabatan_umum_abk/autocomplete_opd_unit_kerja", {
        search: search,
        id_opd: vm.form.id_opd
      });
      if (search) vm.optionsUnitKerja = options;
    }, 300),
    async onSearchJabatan(search, loading) {
      loading(true);
      await this.searchJabatan(search, this);
      loading(false);
    },
    searchJabatan: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("jabatan_umum_abk/autocomplete_opd_jabatan", {
        search: search,
        id_opd: vm.form.id_opd,
        id_unit_kerja: vm.form.id_unit_kerja
      });
      if (search) vm.optionsJabatanUmum = options;
    }, 500),
    onChangesJabatan(val){
      this.form.id_jabatan_umum = val.id_jabatan;
      this.form.id_mst_jabatan = val.id;
    },
    async onSearchOPD(search, loading) {
      loading(true);
      await this.searchOPD(search, this);
      loading(false);
    },
    searchOPD: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "jabatan_umum_abk/autocomplete_opd",
        search
      );
      if (search) vm.optionsOPD = options;
    }, 300),
  },
};
</script>
